import { authActions } from './auth';
import { pricingsAction } from './pricing';
import { shopsAction } from './shop';
import { uisAction } from './ui';
import { usersAction } from './users';

export default {
  ...usersAction,
  ...authActions,
  ...uisAction,
  ...shopsAction,
  ...pricingsAction
};
