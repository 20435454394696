import axiosConfig from '../../config/axiosConfig';
import { getToken } from '../../helpers/token';
export const shopsAction = {
  async getAllShopAllStatus(context) {
    try {
      const res = await axiosConfig({
        url: `/shop?type=get_all&status_shop[]=active&status_shop[]=disable&status[]=active&status[]=pending&status[]=disable`,
        method: 'get',
        token: getToken()
      });
      if (res.status === 200 || res.status === 201) {
        context.commit('GET_ALL_SHOPS_ALL_STATUS', res.data);
      }
    } catch (e) {
      throw new Error(e);
    }
  },
  async getAllShops(context) {
    try {
      const res = await axiosConfig({
        url: `/shop?type=get_all&status[]=active&status[]=pending&status[]=disable`,
        method: 'get',
        token: getToken()
      });
      if (res.status === 200 || res.status === 201) {
        context.commit('GET_ALL_SHOPS', res.data);
      }
    } catch (e) {
      throw new Error(e);
    }
  },

  async getShopDetails(context, payload) {
    try {
      const res = await axiosConfig.get(`/shop/${payload.shopId}`);
      if (res.status === 200 || res.status === 201) {
        context.commit('GET_SHOP_DETAILS', res.data);
      } else {
        context.commit('GET_SHOP_DETAILS_ERROR', {
          onError: payload.navigationError
        });
      }
    } catch (error) {
      context.commit('GET_SHOP_DETAILS_ERROR', {
        onError: payload.navigationError
      });
    }
  },

  async clearShopDetail(context) {
    context.commit('CLEAR_SHOP_DETAILS');
  },

  async updateShopInfo(context, payload) {
    try {
      const res = await axiosConfig.put(
        `/shop/${payload.shopId}`,
        payload.shopInfo
      );
      if (res.status === 200 || res.status === 201) {
        context.commit('UPDATE_SHOP_INFO_SUCCESS', {
          data: res.data,
          reload: payload.reload
        });
      } else {
        context.commit('UPDATE_SHOP_INFO_ERROR', res.data);
      }
    } catch (error) {}
  },

  async deleteShop(context, payload) {
    try {
      const res = await axiosConfig.delete(`/shop/${payload.shopId}`);
      if (res.status === 200 || res.status === 201) {
        context.commit('DELETE_SHOP_SUCCESS', {
          shopId: payload.shopId,
          navigation: payload.navigation
        });
      } else {
        context.commit('DELETE_SHOP_ERROR', res.data);
      }
    } catch (error) {
      throw new Error(error);
    }
  },

  async deletePermanentlyShop(context, payload) {
    try {
      const res = await axiosConfig.delete(
        `/shop/delete-forever/${payload.shopId}`
      );
      if (res.status === 200 || res.status === 201) {
        context.commit('DELETE_PERMANENTLY_SHOP_SUCCESS', {
          shopId: payload.shopId,
          navigation: payload.navigation
        });
      } else {
        context.commit('DELETE_PERMANENTLY_SHOP_ERROR');
      }
    } catch (error) {
      throw new Error(error);
    }
  },

  async restoreShop(context, payload) {
    try {
      const res = await axiosConfig.put(`/shop/restore/${payload.shopId}`);
      if (res.status === 200 || res.status === 201) {
        context.commit('RESTORE_SHOP_SUCCESS', {
          shopId: payload.shopId,
          navigation: payload.navigation
        });
      } else {
        context.commit('RESTORE_SHOP_ERROR');
      }
    } catch (error) {
      throw new Error(error);
    }
  },

  async getAreaList(context) {
    try {
      const res = await axiosConfig('/location');
      if (res.status === 200 || res.status === 201) {
        context.commit('GET_AREA_LIST', res.data);
      }
    } catch (error) {
      throw new Error(error);
    }
  },

  async getShopRequestList(context) {
    try {
      const res = await axiosConfig({
        url: `/register-shop`,
        method: 'get',
        token: getToken()
      });
      if (res.status === 200 || res.status === 201) {
        context.commit('GET_SHOP_REQUEST_LIST', res.data);
      }
    } catch (e) {
      throw new Error(e);
    }
  },

  async getShopRequestInfo(context, payload) {
    try {
      const res = await axiosConfig({
        url: `/register-shop/${payload.shopId}`,
        method: 'get',
        token: getToken()
      });
      if (res.status === 200 || res.status === 201) {
        context.commit('GET_SHOP_REQUEST_INFO', res.data);
      } else {
        context.commit('GET_SHOP_REQUEST_INFO_ERROR', {
          // onError: payload.navigationError
        });
      }
    } catch (error) {
      context.commit('GET_SHOP_REQUEST_INFO_ERROR', {
        // onError: payload.navigationError
      });
    }
  },
  async clearShopRequestInfo(context) {
    context.commit('CLEAR_SHOP_REQUEST_INFO');
  },
  async deleteRequestCreateShop(context, payload) {
    try {
      const res = await axiosConfig.delete(`/register-shop/${payload?.shopId}`);
      if (res.status === 200 || res.status === 201) {
        context.commit('DELETE_REQUEST_SUCCESS', {
          shopId: payload.shopId
        });
      } else {
        context.commit('DELETE_REQUEST_ERROR');
      }
    } catch (error) {
      throw new Error(error);
    }
  },
  async createShop(context, payload) {
    try {
      const res = await axiosConfig.post(`/shop/create-shop`, payload.shopInfo);
      if (
        (res.status === 200 || res.status === 201) &&
        (res.data?.status !== 500 || !res.data?.status)
      ) {
        context.commit('CREATED_REQUEST_SUCCESS', {
          payload: payload.shopId ?? -1,
          onSuccess: payload.onSuccess
        });
      } else {
        context.commit('CREATED_REQUEST_ERROR');
      }
    } catch (error) {}
  },
  async getTotalGiftPoint(context, payload) {
    try {
      const res = await axiosConfig.get(
        `/daily-report?type_get=get_all&date=${payload.month}&type=month`,
        {
          token: payload.token
        }
      );
      if (res.status === 200 || res.status === 201) {
        context.commit('GET_GIFT_POINT_DASHBOARD', res.data.giftPoint);
      }
    } catch (error) {
      throw new Error(error);
    }
  },
  async getTotalLiveStreamTime(context, payload) {
    try {
      const res = await axiosConfig.get(
        `/daily-report?type_get=get_all&date=${payload.month}&type=month`,
        { token: payload.token }
      );
      if (res.status == 200) {
        context.commit('GET_LIVE_STREAMER_DASHBOARD', res.data.liveStreamer);
      }
    } catch (error) {
      console.log('get dashboard daily report view list failed: ', error);
    }
  },
  async resendEmail(context, payload) {
    try {
      const res = await axiosConfig({
        method: 'POST',
        url: '/shop/send-mail-infor-shop',
        data: {
          email: payload.email
        },
        token: payload.token
      });
      if (res.status === 200 || res.status === 201) {
        context.commit('RESEND_EMAIL_SUCCESS', res.data);
      } else {
        context.commit('RESEND_EMAIL_ERROR', res.data);
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      payload.onOffLoadingSendEmail();
    }
  },
  async getLiveStreamTimeModal(context, payload) {
    try {
      const res = await axiosConfig(
        `/live-streamer/list?type_get=get_all&date=${payload.month}&type=month`,
        { token: payload.token }
      );
      if (res.status === 200 || res.status === 201) {
        context.commit(
          'GET_TOTAL_STREAMING_TIME_MODAL_SUCCESS',
          res.data.liveStreamers
        );
      }
    } catch (error) {
      console.log('error: ', error);
    } finally {
      console.log('finally');
    }
  },
  async getTotalLiveStreamDurationModal(context, payload) {
    try {
      const res = await axiosConfig(
        `/live-streamer/list?type_get=get_all&date=${payload.month}&type=month`,
        { token: payload.token }
      );
      if (res.status === 200 || res.status === 201) {
        context.commit(
          'GET_TOTAL_STREAMING_DURATION_MODAL_SUCCESS',
          res.data.liveStreamers
        );
      }
    } catch (error) {
      console.log('error: ', error);
    } finally {
      console.log('finally');
    }
  },
  async getShopGiftPointModal(context, payload) {
    try {
      const res = await axiosConfig(
        `/live-streamer/list?type_get=get_all&date=${payload.month}&type=month`,
        { token: payload.token }
      );
      if (res.status === 200 || res.status === 201) {
        context.commit('GET_GIFT_POINT_MODAL_SUCCESS', res.data.giftPoints);
      }
    } catch (error) {
      console.log('error: ', error);
    } finally {
      console.log('finally');
    }
  }
};
