import { pricingsState } from './pricing';
import { shopsState } from './shop';
import { uisState } from './ui';
import { usersState } from './users';

export default { 
    ...usersState,
    ...uisState,
    ...shopsState,
    ...pricingsState
};
