import { useI18n } from "vue-i18n";

export const listStatusUserFilter = () => {
    const { t } = useI18n();
    return [
    { id: 0, status: 'all', name: t("common.all_accounts") },
    { id: 1, status: 'active', name: t("common.current_account") },
    { id: 2, status: 'disable', name: t("common.unauthorized_account") },
  ];
};

export const menusUserTab = () => {
  const { t } = useI18n();
  return [
    // {
    //   id: 1,
    //   name: t("common.total_number_of_users"),
    //   path: "/user-management/total-users",
    // },
    {
      id: 2,
      name: t("common.users_by_store"),
      path: "/user-management/users-by-store",
    },
    {
      id: 3,
      name: t("common.account_permission"),
      path: "/user-management/account-permission",
    },
  ];
}