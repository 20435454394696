<template>
  <div class="bg-white max-w-[800px] w-full max-h-[610px] h-full rounded-lg">
    <div
      class="header flex justify-between items-center px-6 py-3 border-b border-[#D0D5DD]"
    >
      <div class="header-left text-[#1D2939] noto700 text-[20px] leading-7">
        {{
          props.type === 'shop-active'
            ? $t('common.shop_by_contract')
            : $t('common.in_active_shop')
        }}
        ({{ props.data.length }})
      </div>
      <div
        class="header-right text-[13px] noto500 text-[#667085] cursor-pointer"
        @click="props.onClose()"
      >
        {{ $t('common.close') }}
      </div>
    </div>
    <div class="body p-3 h-full">
      <div class="search-bar w-full flex justify-end">
        <label
          for="search-input"
          class="w-fit h-fit flex gap-2 items-center rounded-lg pl-4 border border-gray-border bg-white cursor-text"
        >
          <label
            class="cursor-pointer"
            for="search-input"
            v-html="searchIcon"
          ></label>
          <input
            id="search-input"
            class="outline-0 py-[10px] noto400"
            type="text"
            v-model="searchKey"
            autocomplete="off"
            @keydown.enter="searchShopRender(searchKey)"
            :placeholder="$t('common.explore_the_store')"
          />
          <button
            :disabled="searchKey == ''"
            class="text-white bg-orage-text border-l border-l-gray-border h-[44px] px-3 rounded-r-[7px] whitespace-nowrap"
            @click="searchShopRender(searchKey)"
          >
            {{ $t('common.search_master') }}
          </button>
        </label>
      </div>
      <div
        class="shop-table grid grid-cols-2 gap-3 w-full mt-3 overflow-y-auto max-h-[calc(100%-112px)] scroll-bar pr-2"
      >
        <ShopInfoItem
          v-for="(item, index) in dataRender"
          :key="index"
          :data="item"
          :onClose="() => props.onClose()"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, watch } from 'vue';
import { searchIcon } from '../../../../assets/constant/constant';
import { useDebounce } from '../../../../helpers';
import ShopInfoItem from './ShopInfoItem.vue';

const props = defineProps({
  type: {
    type: String,
    required: true
  },
  onClose: {
    type: Function,
    required: true
  },
  data: {
    type: Array,
    required: true
  }
});
// ref
const searchKey = ref('');
const dataRender = ref(props.data);
// functions
const searchShopRender = (key) => {
  if (key === '') {
    dataRender.value = props.data;
  } else {
    const searchToLowerCase = key.toLowerCase();
    const searchResult = dataRender.value?.filter((shop) => {
      return (
        searchToLowerCase.includes(shop.name?.toLowerCase()) ||
        shop.name?.toLowerCase().includes(searchToLowerCase)
      );
    });
    dataRender.value = searchResult;
  }
};
const searchShopRenderDebounce = useDebounce(searchShopRender, 500);
// watch
watch(searchKey, () => {
  searchShopRenderDebounce(searchKey.value);
});
</script>
<style lang="scss">
.shop-table {
  @media screen and (max-width: 850px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
</style>