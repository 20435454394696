<template>
  <div class="bg-white max-w-[800px] w-full max-h-[610px] h-full rounded-lg">
    <div
      class="header flex justify-between items-center px-6 py-3 border-b border-[#D0D5DD]"
    >
      <div class="header-left text-[#1D2939] noto700 text-[20px] leading-7">
        {{
          props.type === 'number-of-live'
            ? $t('common.its_live')
            : props.type === 'livestream-time'
            ? $t('common.total_live_time')
            : $t('common.total_gift_amount')
        }}
        {{
          `(${
            props.type === 'number-of-live'
              ? props.totalLiveStreamTimeHeader
              : props.type === 'livestream-time'
              ? props.totalLiveStreamingDurationHeader
              : props.totalGiftPointHeader
          })`
        }}
      </div>
      <div
        class="header-right text-[13px] noto500 text-[#667085] cursor-pointer"
        @click="props.onClose()"
      >
        {{ $t('common.close') }}
      </div>
    </div>
    <div class="body p-3 h-full">
      <div class="search-bar w-full flex justify-between items-center">
        <div v-if="props.type === 'number-of-live'"></div>
        <div class="date-picker" v-if="props.type === 'livestream-time'">
          <a-date-picker
            :allowClear="false"
            :locale="locale"
            picker="month"
            format="YYYY年M月"
            v-model:value="totalLiveDurationSelectedDateRef"
          >
            <template #suffixIcon>
              <span v-html="DateIcon"></span>
            </template>
          </a-date-picker>
        </div>
        <div class="date-picker" v-if="props.type === 'total-gift'">
          <a-date-picker
            :allowClear="false"
            :locale="locale"
            picker="month"
            format="YYYY年M月"
            v-model:value="totalGiftSelectedDateRef"
          >
            <template #suffixIcon>
              <span v-html="DateIcon"></span>
            </template>
          </a-date-picker>
        </div>
        <label
          for="search-input"
          class="w-fit h-fit flex gap-2 items-center rounded-lg pl-4 border border-gray-border bg-white cursor-text"
        >
          <label
            class="cursor-pointer"
            for="search-input"
            v-html="searchIcon"
          ></label>
          <input
            id="search-input"
            class="outline-0 py-[10px] noto400"
            type="text"
            v-model="searchKey"
            autocomplete="off"
            @keydown.enter="searchShopRender(searchKey)"
            :placeholder="$t('common.explore_the_store')"
          />
          <button
            :disabled="searchKey == ''"
            class="text-white bg-orage-text border-l border-l-gray-border h-[44px] px-3 rounded-r-[7px] whitespace-nowrap"
            @click="searchShopRender(searchKey)"
          >
            {{ $t('common.search_master') }}
          </button>
        </label>
      </div>
      <div
        class="w-full h-full max-h-[calc(100%-112px)] mt-3 border border-[#D0D5DD] rounded-lg"
      >
        <table class="w-full h-fit text-center">
          <thead>
            <tr class="bg-[#F38744] text-white border-b border-[#D0D5DD]">
              <th class="w-1/2 py-[13px] rounded-tl-lg noto400 font-[400]">
                {{ $t('common.shop') }}
              </th>
              <th class="w-1/2 py-[13px] rounded-tr-lg noto400 font-[400]">
                {{
                  props.type === 'number-of-live'
                    ? $t('common.its_live')
                    : props.type === 'livestream-time'
                    ? $t('common.total_live_time')
                    : $t('common.total_gift_amount')
                }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-if="props.type === 'number-of-live'"
              class="border-b border-[#D0D5DD]"
              v-for="(item, index) in dataRenderTotalLiveStreamTime"
              :key="index"
            >
              <td class="h-fit py-[13px] text-center">{{ item.shopName }}</td>
              <td class="h-fit py-[13px] text-center">
                {{ item.totalStreamingTime }}
              </td>
            </tr>
            <tr
              v-if="props.type === 'livestream-time'"
              class="border-b border-[#D0D5DD]"
              v-for="(item, index) in dataRenderTotalLiveStreamingDuration"
              :key="index"
            >
              <td class="h-fit py-[13px] text-center">{{ item.shopName }}</td>
              <td class="h-fit py-[13px] text-center">
                {{ calculateLiveStreamTime(item.totalSeconds) }}
              </td>
            </tr>
            <tr
              v-if="props.type === 'total-gift'"
              class="border-b border-[#D0D5DD]"
              v-for="(item, index) in dataRenderTotalGiftPointByShop"
              :key="index"
            >
              <td class="h-fit py-[13px] text-center">{{ item.shopName }}</td>
              <td class="h-fit py-[13px] text-center">
                {{ item.totalPoints }}
              </td>
            </tr>
            <!-- Handle no data with type  -->
            <tr
              v-if="
                !dataRenderTotalLiveStreamTime.length &&
                props.type === 'number-of-live'
              "
              class="border-b border-[#D0D5DD] text-center"
            >
              <td colspan="2" class="py-3">
                {{ $t('common.no_data') }}
              </td>
            </tr>
            <tr
              v-if="
                !dataRenderTotalLiveStreamingDuration.length &&
                props.type === 'livestream-time'
              "
              class="border-b border-[#D0D5DD] text-center"
            >
              <td colspan="2" class="py-3">
                {{ $t('common.no_data') }}
              </td>
            </tr>
            <tr
              v-if="
                !dataRenderTotalGiftPointByShop.length &&
                props.type === 'total-gift'
              "
              class="border-b border-[#D0D5DD] text-center"
            >
              <td colspan="2" class="py-3">
                {{ $t('common.no_data') }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
  <script setup>
import { computed, onMounted, ref, watch } from 'vue';
import { searchIcon } from '../../../../assets/constant/constant';
import { formatPoint, useDebounce } from '../../../../helpers';
import locale from 'ant-design-vue/es/date-picker/locale/ja_JP';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
import { DateIcon } from '../../../../assets/constant/svg';
import { useStore } from 'vuex';
import { getToken } from '../../../../helpers/token';
import moment from 'moment';
dayjs.locale('ja');
// define
const store = useStore();
const props = defineProps({
  type: {
    type: String,
    required: true
  },
  onClose: {
    type: Function,
    required: true
  },
  totalLiveDurationSelectedDate: {
    required: false
  },
  totalGiftSelectedDate: {
    required: false
  },
  totalOfLive: {
    require: false
  },
  totalLiveStreamTimeHeader: {
    require: false
  },
  totalLiveStreamingDurationHeader: {
    require: false
  },
  totalGiftPointHeader: {
    require: false
  }
});
// ref
const searchKey = ref('');
const totalLiveDurationSelectedDateRef = ref(
  props.totalLiveDurationSelectedDate
);
const totalGiftSelectedDateRef = ref(props.totalGiftSelectedDate);
const dataRenderTotalLiveStreamTime = ref([]);
const dataRenderTotalLiveStreamingDuration = ref([]);
const dataRenderTotalGiftPointByShop = ref([]);

// computed
const totalLiveStreamTime = computed(() => {
  return (
    Object.entries(store.getters.getTotalStreamingTimeGetters).map(
      ([key, value]) => ({
        shopName: key,
        totalStreamingTime: formatPoint(value.length)
      })
    ) ?? []
  );
});
const totalLiveStreamingDuration = computed(() => {
  return (
    Object.entries(store.getters.getTotalStreamingDurationGetters).map(
      ([key, value]) => ({
        shopName: key,
        totalSeconds: value.totalDuration
      })
    ) ?? []
  );
});
const totalGiftPointByShop = computed(() => {
  return (
    Object.entries(store.getters.getPointsByShopGetters).map(
      ([key, value]) => ({
        shopName: key,
        totalPoints: formatPoint(value.totalPoints)
      })
    ) ?? []
  );
});

// functions
const searchShopRender = (searchKey) => {
  if (searchKey !== '') {
    const searchToLowerCase = searchKey.toLowerCase();
    if (props.type === 'number-of-live') {
      const searchResult = totalLiveStreamTime.value.filter((shop) => {
        return (
          searchToLowerCase.includes(shop.shopName?.toLowerCase()) ||
          shop.shopName?.toLowerCase().includes(searchToLowerCase)
        );
      });
      dataRenderTotalLiveStreamTime.value = searchResult;
    } else if (props.type === 'livestream-time') {
      const searchResult = totalLiveStreamingDuration.value.filter((shop) => {
        return (
          searchToLowerCase.includes(shop.shopName?.toLowerCase()) ||
          shop.shopName?.toLowerCase().includes(searchToLowerCase)
        );
      });
      dataRenderTotalLiveStreamingDuration.value = searchResult;
    } else if (props.type === 'total-gift') {
      const searchResult = totalGiftPointByShop.value.filter((shop) => {
        return (
          searchToLowerCase.includes(shop.shopName?.toLowerCase()) ||
          shop.shopName?.toLowerCase().includes(searchToLowerCase)
        );
      });
      dataRenderTotalGiftPointByShop.value = searchResult;
    }
  } else {
    if (props.type === 'number-of-live') {
      dataRenderTotalLiveStreamTime.value = totalLiveStreamTime.value;
    } else if (props.type === 'livestream-time') {
      dataRenderTotalLiveStreamingDuration.value =
        totalLiveStreamingDuration.value;
    } else if (props.type === 'total-gift') {
      dataRenderTotalGiftPointByShop.value = totalGiftPointByShop.value;
    }
  }
};
const searchShopRenderDebounce = useDebounce(searchShopRender, 500);
const calculateLiveStreamTime = (totalSeconds) => {
  const differenceInSeconds = totalSeconds;
  const hours = Math.floor(differenceInSeconds / 3600);
  const minutes = Math.floor((differenceInSeconds % 3600) / 60);
  const seconds = differenceInSeconds % 60;

  return `${String(formatPoint(hours)).padStart(2, '0')}:${String(
    minutes
  ).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
};
// watch
watch(searchKey, () => {
  searchShopRenderDebounce(searchKey.value);
});
watch(totalLiveDurationSelectedDateRef, () => {
  store.dispatch('getTotalLiveStreamDurationModal', {
    token: getToken(),
    month: dayjs(totalLiveDurationSelectedDateRef.value.$d).format('YYYY-MM-DD')
  });
});
watch(totalGiftSelectedDateRef, () => {
  store.dispatch('getShopGiftPointModal', {
    token: getToken(),
    month: dayjs(totalGiftSelectedDateRef.value.$d).format('YYYY-MM-DD')
  });
});
watch(totalLiveStreamTime, () => {
  dataRenderTotalLiveStreamTime.value = totalLiveStreamTime.value;
});
watch(totalLiveStreamingDuration, () => {
  dataRenderTotalLiveStreamingDuration.value = totalLiveStreamingDuration.value;
});
watch(totalGiftPointByShop, () => {
  dataRenderTotalGiftPointByShop.value = totalGiftPointByShop.value;
});
onMounted(() => {
  switch (props.type) {
    case 'number-of-live':
      store.dispatch('getLiveStreamTimeModal', {
        token: getToken(),
        month: moment().format('YYYY-MM-DD')
      });
      break;
    case 'livestream-time':
      store.dispatch('getTotalLiveStreamDurationModal', {
        token: getToken(),
        month: dayjs(totalLiveDurationSelectedDateRef.value.$d).format(
          'YYYY-MM-DD'
        )
      });
      break;
    case 'total-gift':
      store.dispatch('getShopGiftPointModal', {
        token: getToken(),
        month: dayjs(totalGiftSelectedDateRef.value.$d).format('YYYY-MM-DD')
      });
      break;
  }
});
</script>
<style lang="scss" scoped>
.date-picker {
  .ant-picker {
    width: 142px !important;
    height: 36px;
    padding: 12px 14px;
    border: 1px solid #d0d5dd;
    input {
      text-align: left !important;
      color: #1d2939 !important;
      font-size: 16px;
    }
  }
}
</style>