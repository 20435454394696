<template>
  <div class="wrap" v-if="true">
    <div class="left" v-if="!sideBarHide">
      <SideBar />
    </div>
    <div class="right bg-[#f9fafb]">
      <div
        :class="{
          main: true,
          '!pl-[0px]': sideBarHide || layoutMobile
        }"
        id="main"
      >
        <div
          class="w-full flex justify-start items-center ml-[24px] mt-[11px] mb-[0px] bg-[#f9fafb] translate-y-[7px]"
          v-if="layoutMobile && !checkSingleLayout"
        >
          <span
            class="max-w-[50px] max-h-[50px] cursor-pointer"
            v-html="MenuIconMobile"
            @click="setStatusHideSidebar"
          ></span>
        </div>
        <Main />
      </div>
    </div>
  </div>
  <div class="wrap-two" v-else>
    <keep-alive :include="cachedComponents">
      <router-view> </router-view>
    </keep-alive>
  </div>
</template>

<script setup>
import {
  computed,
  onBeforeMount,
  onMounted,
  onUnmounted,
  watch,
  ref
} from 'vue';
import VueCookies from 'vue-cookies';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import Main from './components/master/Main';
import SideBar from './components/master/SideBar';
import { getToken } from './helpers/token';
import { MenuIconMobile } from './assets/constant/svg.js';

const store = useStore();
const route = useRoute();
const router = useRouter();

const checkSingleLayout = ref(true);
const routesSingleLayout = ['/', '/login'];
const sideBarHide = computed(() => store.state.hideSidebar);
const layoutMobile = computed(() => store.state.layoutMobile);

watch(
  () => route.path,
  (newPath) => {
    updateWindowWidth();
    handleRoute();
    handleFocusSidebar(newPath);
  }
);
const handleFocusItemSubMenu = (key, index) => {
  store.dispatch('setSidebarItemChildFocus', {
    key: key,
    index: index
  });
};
const handleFocusSidebar = (newPath) => {
  handleFocusItemSubMenu(3, null);
  if (newPath === '/dashboard') {
    store.dispatch('setSidebarFocus', 1);
  } else if (newPath.includes('/store-management')) {
    store.dispatch('setSidebarFocus', 2);
  } else if (newPath.includes('/user-management')) {
    store.dispatch('setSidebarFocus', 3);
    if (newPath.includes('/total-users')) {
      handleFocusItemSubMenu(3, 1);
    } else if (newPath.includes('/users-by-store')) {
      handleFocusItemSubMenu(3, 2);
    } else if (newPath.includes('/account-permission')) {
      handleFocusItemSubMenu(3, 3);
    }
  } else if (newPath.includes('/extended-management')) {
    store.dispatch('setSidebarFocus', 4);
  } else {
    store.dispatch('setSidebarFocus', 1);
  }
};

const setStatusHideSidebar = () => {
  event.stopPropagation();
  store.dispatch('setStatusHideSidebar', !sideBarHide.value);
};

const handleRoute = async () => {
  store.dispatch('setStatusHideSidebar', true);
  await router.isReady();
  let currentPathObject = router.currentRoute.value;
  if (!getToken()) {
    store.dispatch('setStatusHideSidebar', true);
    VueCookies.remove('access_token_master');
    router.push('/login');
    checkSingleLayout.value = true;
    return;
  } else {
    const flagSingleLayout = routesSingleLayout.some((routes) => {
      const path = currentPathObject.path;
      if (path === routes || currentPathObject.name === 'NotFound') {
        if (path === '/login' || path === '/') {
          router.push('/dashboard');
        }
        store.dispatch('setStatusHideSidebar', true);
        return true;
      }
      return false;
    });
    checkSingleLayout.value = flagSingleLayout;
    if (!flagSingleLayout && sideBarHide.value && !layoutMobile.value) {
      store.dispatch('setStatusHideSidebar', false);
    }
  }
};

const updateWindowWidth = () => {
  const viewportMeta = document.getElementById('viewportMeta');
  if (!checkSingleLayout.value) {
    if (window.innerWidth <= 1200) {
      store.dispatch('setLayoutMobile', true);
      store.dispatch('setStatusHideSidebar', true);
      viewportMeta.setAttribute(
        'content',
        'width=device-width, initial-scale=0.8, maximum-scale=0.8, user-scalable=no'
      );
    } else {
      store.dispatch('setStatusHideSidebar', false);
      store.dispatch('setLayoutMobile', false);
      viewportMeta.setAttribute(
        'content',
        'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no'
      );
    }
  }
};

watch(checkSingleLayout, () => {
  updateWindowWidth();
});

onBeforeMount(() => {
  handleRoute();
});

onMounted(() => {
  updateWindowWidth();
  window.addEventListener('resize', updateWindowWidth);
});

onUnmounted(() => {
  window.removeEventListener('resize', updateWindowWidth);
});
</script>
