import { showAlert } from '../../helpers';

export const usersMutation = {
  SAVE_USER_INFO(state, payload) {
    state.userInShop.push(payload);
    showAlert(
      'success',
      localStorage.getItem('lang') === 'en'
        ? 'User saved successfully'
        : 'ユーザーの保存に成功しました。',
      'top-center'
    );
  },

  SAVE_USER_INFO_ERROR() {
    showAlert(
      'error',
      localStorage.getItem('lang') === 'en'
        ? 'User saved failed, user id already exist'
        : 'ユーザーの保存に失敗しました。ユーザーIDが存在しました。 ',
      'top-center'
    );
  },
  GET_USER_LIVE(state, payload) {
    state.userLive = [];
    state.userInShop?.forEach((user) => {
      payload.forEach((userLive) => {
        if (user._id === userLive?.user?._id) {
          state.userLive.push(userLive);
        }
      });
    });
  },
  GET_ALL_USERS(state, payload) {
    state.allUsers = payload;
  },
  GET_USER_IN_SHOP(state, payload) {
    state.userInShop = payload;
  },
  GET_USER_DETAILS(state, payload) {
    state.userDetails = payload;
  },
  ENABLE_LIVE(state, payload) {
    state.userLive = state.userLive.filter((user) => {
      return user?.user?._id !== payload;
    });
  },
  CLEAR_USER_LIVE(state) {
    state.userLive = [];
  },
  ALLOW_PERMISSION(state, payload) {
    payload.onSuccess();
    showAlert(
      'success',
      localStorage.getItem('lang') === 'en'
        ? 'Authorization successful'
        : '正常に動作する許可が与えられました',
      'top-center'
    );
  },
  ALLOW_PERMISSION_ERROR(state) {
    showAlert(
      'error',
      localStorage.getItem('lang') === 'en'
        ? 'Operation authorization failed'
        : '操作の認証に失敗しました',
      'top-center'
    );
  },
  ALLOW_PERMISSION_WHEN_ACTIVE_SUCCESS(state, payload) {
    payload.onSuccess();
    showAlert(
      'success',
      localStorage.getItem('lang') === 'en'
        ? 'Account have been successfully activated'
        : 'アカウントが正常に有効化されました',
      'top-center'
    );
  },
  ALLOW_PERMISSION_WHEN_ACTIVE_ERROR(state) {
    showAlert(
      'error',
      localStorage.getItem('lang') === 'en'
        ? 'Operation authorization failed'
        : '操作の認証に失敗しました',
      'top-center'
    );
  },
  RESTORE_USER(state, payload) {
    showAlert(
      'success',
      localStorage.getItem('lang') === 'en'
        ? 'User restored successfully'
        : 'ユーザーが正常に復元されました',
      'top-center'
    );
    payload.onSuccess();
  },
  DELETE_USER(state, payload) {
    showAlert(
      'success',
      localStorage.getItem('lang') === 'en'
        ? 'Deleted account successfully'
        : 'アカウントが正常に削除されました',
      'top-center'
    );
    payload.onSuccess();
  },

  DELETE_USER_ERROR(state) {
    showAlert(
      'error',
      localStorage.getItem('lang') === 'en'
        ? 'Deleted account failed'
        : 'アカウントの削除に失敗しました',
      'top-center'
    );
  },

  DISABLE_USER(state, payload) {
    showAlert(
      'success',
      localStorage.getItem('lang') === 'en'
        ? 'Account hidden successfully.'
        : 'アカウントが正常に非表示になりました',
      'top-center'
    );
    payload.onSuccess();
  },

  DISABLE_USER_ERROR(state) {
    showAlert(
      'error',
      localStorage.getItem('lang') === 'en'
        ? 'Failed to hide the account.'
        : 'アカウントの非表示に失敗しました',
      'top-center'
    );
  },

  DELETE_PERMANENTLY_USERS(state, payload) {
    showAlert(
      'success',
      localStorage.getItem('lang') === 'en'
        ? 'Account has been permanently deleted'
        : 'アカウントは永久に削除されました',
      'top-center'
    );
    payload.onSuccess();
  },

  DELETE_PERMANENTLY_USERS_ERROR() {
    showAlert(
      'error',
      localStorage.getItem('lang') === 'en'
        ? 'Permanent account deletion failed'
        : 'アカウントの永久削除に失敗しました',
      'top-center'
    );
  },
  UPDATE_USER_INFO(state, payload) {
    state.userList = state.userInShop?.map((item) => {
      if (item?._id === payload?._id) {
        return { ...item, ...payload };
      }
      return item;
    });
    state.userDetails = payload;
    showAlert(
      'success',
      localStorage.getItem('lang') === 'en'
        ? 'User updated successfully'
        : 'ユーザーの更新に成功しました。',
      'top-center'
    );
  },
  UPDATE_USER_INFO_ERROR(state) {
    showAlert(
      'error',
      localStorage.getItem('lang') === 'en'
        ? 'User updated failed, user id already exist'
        : 'ユーザーの更新に失敗しました。ユーザーIDが存在しました。 ',
      'top-center'
    );
  },
  UPDATE_AVATAR_USER_INFO(state, payload) {
    state.userList = state.userList?.map((item) => {
      if (item?._id === payload?._id) {
        return { ...item, ...payload };
      }
      return item;
    });
    // showAlert(
    //   'success',
    //   localStorage.getItem('lang') === 'en'
    //     ? 'User updated successfully'
    //     : 'ユーザーの更新に成功しました。',
    //   'top-center'
    // );
  },

  UPDATE_USER_INFO_ERROR() {
    showAlert(
      'error',
      localStorage.getItem('lang') === 'en'
        ? 'User updated failed, user id already exist'
        : 'ユーザーの更新に失敗しました。ユーザーIDが存在しました。 ',
      'top-center'
    );
  },
  DELETE_USER_AVATAR(state, payload) {
    state.userList = state.userList?.map((item) => {
      if (item?._id === payload?._id) {
        return { ...item, avatar: null };
      }
      return item;
    });
    // showAlert(
    //   'success',
    //   localStorage.getItem('lang') === 'en'
    //     ? 'Deleted avatar successfully'
    //     : 'アバターの削除に成功しました。',
    //   'top-center'
    // );
  },

  DELETE_USER_AVATAR_ERROR() {
    showAlert(
      'error',
      localStorage.getItem('lang') === 'en'
        ? 'Failed to delete avatar, try again later'
        : 'アバターの削除に失敗しました。後で再度お試してください。 ',
      'top-center'
    );
  }
};
