import { getStatusUser, showAlert } from '../../helpers';

export const pricingsMutation = {
  GET_PRICING_LIST(state, payload) {
    state.pricingList = payload;
  },

  BUY_PRICING_SUCCESS(state, payload) {
    state.userInShop = [...state.userInShop.map((user) => {
      if (payload.userIds.includes(user._id)) {
        return {
          ...user,
          packageUser: [
            ...payload.packages?.map((p) => ({
              package: p,
              status: 'active',
            })),
            ...(user?.packageUser || []),
          ],
        };
      }
      return { ...user };
    })];
    setTimeout(() => {
      payload?.onSuccess();
    }, 1000);
  },

  BUY_PRICING_ERROR() {
    showAlert(
      'error',
      localStorage.getItem('lang') === 'en'
        ? 'Account activation failed'
        : 'アカウントの有効化に失敗しました',
      'top-center'
    );
  },
  RENEW_ACCOUNT_SUCCESS() {
    showAlert(
      'success',
      localStorage.getItem('lang') === 'en'
        ? 'Account renewal successful'
        : 'アカウントの更新が成功しました',
      'top-center'
    );
  },
  RENEW_ACCOUNT_ERROR() {
    showAlert(
      'error',
      localStorage.getItem('lang') === 'en'
        ? 'Account renewal failed'
        : 'アカウントの更新に失敗しました',
      'top-center'
    );
  },
  NO_ACCOUNT_SELECTED() {
    showAlert(
      'warning',
      localStorage.getItem('lang') === 'en'
        ? 'No account selected'
        : 'アカウントが選択されていません',
      'top-center'
    );
    localStorage.setItem('userChange', false);
  },
  GET_RENEW_PRICING(state, payload) {
    state.renewPricings = payload;
  }
};
