import axiosConfig from '../../config/axiosConfig';

export const pricingsAction = {
  async getPricingList(context, shopId) {
    try {
      const res = await axiosConfig.get(`/packages-price?shop=${shopId}`);
      context.commit('GET_PRICING_LIST', res.data);
    } catch (error) {
      throw new Error(error);
    }
  },

  async buyPricing(context, data) {
    try {
      const res = await axiosConfig.post('/purchased-packages', data?.pricingInfo);
      res.status === 200 || res.status === 201
        ? context.commit('BUY_PRICING_SUCCESS', {
          userIds: data?.pricingInfo?.user,
          packages: res.data,
          onSuccess: data?.onSuccess
        })
        : context.commit('BUY_PRICING_ERROR');
    } catch (error) {
      throw new Error(error);
    }
  },
  async renewUserAccount(context, data) {
    try {
      const res = await axiosConfig.post('/purchased-packages', data);
      res.status === 200 || res.status === 201
        ? context.commit('RENEW_ACCOUNT_SUCCESS')
        : context.commit('RENEW_ACCOUNT_ERROR');
    } catch (error) {
      throw new Error(error);
    }
  },
  async getRenewPricing(context, data) {
    try {
      const res = await axiosConfig.get(`/purchased-packages?shop=${data.shop}&type=month`);
      if(res.status === 200 || res.status === 201){        
        context.commit("GET_RENEW_PRICING", res.data);
      }
    } catch (error) {
      throw new Error(error);
    }
  }
};
