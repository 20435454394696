import { authsMutation } from './auth';
import { pricingsMutation } from './pricing';
import { shopsMutation } from './shop';
import { uisMutation } from './ui';
import { usersMutation } from './users';
export default { 
    ...usersMutation,
    ...authsMutation, 
    ...uisMutation,
    ...shopsMutation,
    ...pricingsMutation
};
