import { createRouter, createWebHistory } from 'vue-router';
import Login from '../components/views/Auth/Login.vue';
import Dashboard from '../components/views/Dashboard/Dashboard.vue';
import RenewUserAccountPage from '../components/views/Renew/RenewUserAccountPage.vue';
import CreateShopPage from '../components/views/StoreManagermentNew/CreateShopPage.vue';
import ShopInfoPageActive from '../components/views/StoreManagermentNew/ShopInfoPageActive.vue';
import ShopInfoPageDisable from '../components/views/StoreManagermentNew/ShopInfoPageDisable.vue';
import ShopListPage from '../components/views/StoreManagermentNew/ShopListPage.vue';
import StoreApproval from '../components/views/StoreManagermentNew/StoreApproval.vue';
import AccountPermission from '../components/views/UserManagement/AccountPermission.vue';
import TotalUser from '../components/views/UserManagement/TotalUser.vue';
import UserByStore from '../components/views/UserManagement/UserByStore.vue';
// import UserDetailActive from '../components/views/UserManagement/UserDetailActive.vue';
import UserDetailActive from '../components/views/UserManagermentNew/UserDetailActive.vue';
import UserDetailDisable from '../components/views/UserManagement/UserDetailDisable.vue';
import UserDetailPending from '../components/views/UserManagement/UserDetailPending.vue';
import CreateShopPageFromRequest from '../components/views/StoreManagermentNew/CreateShopPageFromRequest.vue';
import UserDetailActiveRenew from '../components/views/Renew/UserDetailActiveRenew.vue';
const routes = [
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('../components/master/404Page.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/',
    name: 'LoginDefault',
    component: Login
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/store-management',
    name: 'Stores',
    component: ShopListPage
  },

  {
    path: '/store-management/create-store',
    name: 'CreateStorePage',
    component: CreateShopPage
  },
  {
    path: '/store-management/store-request/create-store',
    name: 'CreateStorePageFromRequest',
    component: CreateShopPageFromRequest
  },
  {
    path: '/store-management-active',
    name: 'StoreInfoPageActive',
    component: ShopInfoPageActive
  },
  {
    path: '/store-management-active/user-details-active',
    name: 'StoreInfoPageActiveWithUserDetailActive',
    component: UserDetailActive
  },
  {
    path: '/store-management-disable',
    name: 'StoreInfoPageDisable',
    component: ShopInfoPageDisable
  },
  {
    path: '/store-management/store-request',
    name: 'StoreApproval',
    component: StoreApproval
  },
  {
    path: '/user-management/total-users',
    name: 'UserManagementTotalUsers',
    component: TotalUser
  },
  {
    path: '/user-management/total-users/user-details-active',
    name: 'UserManagementTotalUsersUserDetailsActive',
    component: UserDetailActive
  },
  {
    path: '/user-management/account-permission/user-details-pending',
    name: 'UserManagementTotalUsersUserDetailsPending',
    component: UserDetailPending
  },
  {
    path: '/user-management/total-users/user-details-disable',
    name: 'UserManagementTotalUsersUserDetailsDisable',
    component: UserDetailDisable
  },
  {
    path: '/user-management/total-users/user-details-expired',
    name: 'UserManagementTotalUsersUserDetailsExpried',
    component: UserDetailActive
  },
  {
    path: '/user-management/users-by-store/user-details-active',
    name: 'UserManagementUsersByStoreUserDetailsActive',
    component: UserDetailActive
  },
  {
    path: '/user-management/users-by-store/user-details-disable',
    name: 'UserManagementUsersByStoreUserDetailsDisable',
    component: UserDetailDisable
  },
  {
    path: '/user-management/users-by-store/user-details-expired',
    name: 'UserManagementUsersByStoreUserDetailsExpired',
    component: UserDetailActive
  },
  {
    path: '/extended-management/user-details-active',
    name: 'UserDetailsRenewActive',
    component: UserDetailActiveRenew
  },
  {
    path: '/extended-management',
    name: 'RenewUserAccountPage',
    component: RenewUserAccountPage
  },
  {
    path: '/user-management/users-by-store',
    name: 'UserManagementUsersByStore',
    component: UserByStore
  },
  {
    path: '/user-management/account-permission',
    name: 'UserManagementAccountPermission',
    component: AccountPermission
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  mode: 'hash'
});

export default router;
