<template>
  <div
    v-if="!layoutMobile"
    class="store-info-container store-info-disable pb-4 px-[24px] py-[21px]"
  >
    <span class="text-header-bold text-[24px] noto700">
      {{ userTab ? $t('common.personal_info') : nameByIndex }}
    </span>
    <div class="store-breadcrumb pt-[14px] pb-[24px]">
      <Breadcrumd :routes="routes" />
    </div>
    <div
      class="flex justify-center flex-col items-center gap-[24px] h-fit w-full transition-all"
    >
      <div
        :class="{
          'navigation-tab flex w-full items-center self-start border-b border-[#D0D5DD]': true
        }"
      >
        <div v-for="(item, index) in navigateTab" :key="index">
          <div
            :class="{
              'px-5 pb-4 cursor-pointer noto400 ': true,
              'border-b-2 text-[#F38744] border-[#F38744] !font-bold':
                currentTab == index + 1
            }"
            @click="handleNavigateTabClick(index + 1)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
      <div
        v-if="currentTab == 1"
        class="create-store-form bg-white w-full m-auto p-[24px] pb-0 rounded-lg border border-gray-border"
      >
        <div class="form-header flex justify-between items-center">
          <div
            class="form-header-left text-gray600 noto700 text-[20px] uppercase"
          >
            {{ formState.storeName }}
          </div>
          <div
            class="h-[42px] justify-center form-header-middle flex text-delete-text border border-delete-text px-2 py-1 md:py-[13px] md:px-4 rounded-lg items-center gap-1"
          >
            <span v-html="stopIcon"></span>
            STOP
          </div>
          <div class="form-header-right flex gap-2">
            <button
              class="h-[42px] flex justify-center items-center text-delete-text border border-delete-text px-2 py-1 md:py-3 md:px-4 rounded-lg whitespace-nowrap"
              @click="handleDeletePermanentlyStoreAccount"
            >
              {{ $t('common.store_deletion') }}
            </button>
            <button
              class="w-[112px] h-[42px] flex justify-center items-center bg-orage-text text-white px-2 py-1 md:py-[13px] md:px-4 rounded-lg whitespace-nowrap"
              @click="handleRestoreAccount"
            >
              {{ $t('common.restoration') }}
            </button>
          </div>
        </div>

        <a-form :model="formState" autocomplete="off">
          <div class="input-row">
            <div class="input-row-left noto500">
              {{ $t('common.store_logo_new') }}
            </div>
            <div class="input-row-right">
              <a-form-item name="storeLogo">
                <div class="logo-section flex items-center xs:flex-col">
                  <div class="avatar-container xs:mb-[10px]">
                    <div class="store-logo relative">
                      <img
                        class="w-[80px] h-[80px] object-cover rounded-full"
                        :src="getAvatarShopByName(formState?.storeLogo)"
                        @error="(e) => handleImageError(e, 'shop')"
                        @load="(e) => handleImageLoad(e)"
                      />
                      <label
                        class="camera-icon absolute bottom-[-4px] right-[-4px] bg-gray300 w-fit p-2 rounded-full"
                      >
                        <div v-html="CameraIcon"></div>
                      </label>
                    </div>
                  </div>
                  <div
                    class="delete-btn flex items-center gap-[6px] px-4 py-2 border-[1px] border-gray300 rounded-lg ml-4 xs:ml-0"
                  >
                    <div v-html="grayTrashIcon"></div>
                    <div class="text-gray300">
                      {{ $t('common.delete_image') }}
                    </div>
                  </div>
                </div>
              </a-form-item>
            </div>
          </div>
          <div class="input-row">
            <div class="input-row-left noto500">
              {{ $t('common.store_name') }}
            </div>
            <div class="input-row-right">
              <a-form-item name="storeName">
                <a-input
                  class="!text-text-disabled-gray"
                  v-model:value="formState.storeName"
                  readonly
                />
              </a-form-item>
            </div>
          </div>
          <div class="input-row">
            <div class="input-row-left noto500">
              {{ $t('common.store_telephone_number') }}
            </div>
            <div class="input-row-right">
              <a-form-item name="storeTelephoneNumber">
                <a-input
                  class="!text-text-disabled-gray"
                  :type="number"
                  v-model:value="formState.storeTelephoneNumber"
                  readonly
                />
              </a-form-item>
            </div>
          </div>
          <div class="input-row">
            <div class="input-row-left noto500">
              {{ $t('common.address') }}
            </div>
            <div class="input-row-right">
              <a-form-item name="storeAdress">
                <a-input
                  class="!text-text-disabled-gray"
                  v-model:value="formState.storeAdress"
                  readonly
                />
              </a-form-item>
            </div>
          </div>
          <div class="input-row cursor-default">
            <div class="input-row-left noto500">
              {{ $t('common.area') }}
            </div>
            <div class="input-row-right select-disabled">
              <a-form-item name="storeAdress">
                <a-input
                  class="!text-text-disabled-gray"
                  v-model:value="formState.storeArea"
                  readonly
                />
              </a-form-item>
            </div>
          </div>
          <div class="input-row">
            <div class="input-row-left noto500">
              {{ $t('common.account_creation_date') }}
            </div>
            <div class="input-row-right">
              <a-form-item name="storeCreationDate">
                <a-input
                  class="!text-text-disabled-gray"
                  v-model:value="formState.storeCreationDate"
                  readonly
                />
              </a-form-item>
            </div>
          </div>
          <div class="input-row">
            <div class="input-row-left noto500">
              {{ $t('common.store_name') }}
            </div>
            <div class="input-row-right">
              <a-form-item name="email">
                <a-input
                  class="!text-text-disabled-gray"
                  v-model:value="formState.email"
                  readonly
                />
              </a-form-item>
            </div>
          </div>
          <div class="input-row">
            <div class="input-row-left noto500">
              {{ $t('common.store_image') }}
            </div>
            <div class="input-row-right photo">
              <a-form-item name="storePhoto">
                <label
                  class="custom-photo-up border border-gray300 px-4 py-3 rounded-lg text-gray300 flex w-fit items-center whitespace-nowrap"
                >
                  <div v-html="uploadDocumentDisabledIcon"></div>
                  {{ $t('common.upload') }}
                </label>
              </a-form-item>
              <div class="store-photo-list mt-2 flex flex-wrap gap-3">
                <div
                  class="store-photo-item relative"
                  v-if="formState.storePhoto ?? [].length > 0"
                  v-for="(item, index) in formState.storePhoto"
                  :key="index"
                >
                  <img
                    class="store-photo w-[130px] h-[80px] object-cover rounded-lg"
                    :src="item"
                    alt="store-photo"
                  />
                  <div
                    class="delete-photo-icon absolute bottom-1 right-1 bg-gray300 border-[2px] border-white flex items-center justify-center rounded-lg w-[30px] h-[30px]"
                    v-html="trashFilledIconNew"
                    @click="handleDeleteStorePhoto(index)"
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div class="input-submit-row">
            <div class="input-row-left"></div>
            <div class="input-row-right flex justify-end items-center">
              <a-form-item>
                <button class="button-submit !bg-gray300" disabled>
                  <label v-html="SaveSettingFilled" class="mr-[6px]"></label>
                  {{ $t('common.keep') }}
                </button>
              </a-form-item>
            </div>
          </div>
        </a-form>
      </div>
      <div
        v-if="currentTab == 2 && !userTab"
        class="min-w-[1000px] w-full flex items-center justify-center"
      >
        <UserByStore />
      </div>
      <div
        v-if="currentTab == 2 && userTab == 'active'"
        class="min-w-[1000px] w-full flex items-center justify-center"
      >
        <UserDetailActive />
      </div>
      <div
        v-if="currentTab == 2 && userTab == 'disable'"
        class="min-w-[1000px] w-full flex items-center justify-center"
      >
        <UserDetailDisable />
      </div>
      <div
        v-if="currentTab == 2 && userTab == 'pending'"
        class="min-w-[1000px] w-full flex items-center justify-center"
      >
        <UserDetailDisable />
      </div>
      <div
        v-if="currentTab == 2 && userTab == 'deleted'"
        class="min-w-[1000px] w-full flex items-center justify-center"
      >
        <UserDetailDisable />
      </div>
      <div
        v-if="currentTab == 2 && userTab == 'created'"
        class="min-w-[1000px] w-full flex items-center justify-center"
      >
        <UserDetailDisable />
      </div>
      <div
        v-if="currentTab == 2 && userTab == 'expired'"
        class="min-w-[1000px] w-full flex items-center justify-center"
      >
        <UserDetailDisable />
      </div>
      <div
        v-if="currentTab == 3"
        class="min-w-[1000px] w-full flex items-center justify-center"
      >
        <AccountPermission />
      </div>
      <div
        v-if="currentTab == 4"
        class="min-w-[1000px] w-full flex items-center justify-center"
      >
        <RenewUserAccountPage />
      </div>
    </div>
  </div>
  <div
    v-else
    class="store-info-container w-screen store-info-disable pb-4 px-[24px] py-[21px]"
  >
    <span class="text-header-bold text-[24px] noto700">
      {{ $t('common.stores_creation') }}
    </span>
    <div class="store-breadcrumb pt-[14px] pb-[24px]">
      <Breadcrumd :routes="routes" />
    </div>
    <div
      v-if="layoutMobile"
      :class="{
        'navigation-tab flex w-full items-center self-start  my-3 py-2  whitespace-nowrap overflow-auto': true
      }"
    >
      <div v-for="(item, index) in navigateTab" :key="index">
        <div
          :class="{
            'px-5 pb-2 cursor-pointer noto400 ': true,
            'border-b-2 text-[#F38744] border-[#F38744] !font-bold':
              currentTab == index + 1
          }"
          @click="handleNavigateTabClick(index + 1)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    <button
      v-if="currentTab == 1 && layoutMobile"
      class="w-full h-[42px] flex justify-center items-center bg-orage-text text-white px-2 py-1 md:py-[13px] mb-[24px] rounded-lg whitespace-nowrap"
      @click="handleRestoreAccount"
    >
      {{ $t('common.restoration') }}
    </button>
    <div
      v-if="currentTab == 1 && layoutMobile"
      class="bg-white w-full p-[24px] pb-0 rounded-lg border border-gray-border"
    >
      <div
        class="form-header flex flex-col justify-start items-center gap-[20px]"
      >
        <div
          class="h-[42px] justify-center form-header-middle flex text-delete-text border border-delete-text px-2 py-1 rounded-lg items-center gap-1"
        >
          <span v-html="stopIcon"></span>
          STOP
        </div>
        <div class="shop-name text-gray600 noto700 text-[20px] uppercase">
          {{ formState.storeName }}
        </div>
      </div>

      <a-form :model="formState" autocomplete="off" class="w-full min-w-full">
        <div
          class="input-row w-full flex !flex-row justify-start items-start gap-[12px]"
        >
          <div class="input-row-left noto500">
            {{ $t('common.store_logo_new') }}
          </div>
          <div class="input-row-right">
            <a-form-item name="storeLogo">
              <div
                class="logo-section flex items-center justify-end gap-[12px]"
              >
                <div class="avatar-container xs:mb-[10px] w-[80px] h-[80px]">
                  <div class="store-logo relative w-[80px] h-[80px]">
                    <img
                      class="w-[80px] h-[80px] object-cover rounded-full"
                      :src="getAvatarShopByName(formState?.storeLogo)"
                      @error="(e) => handleImageError(e, 'shop')"
                      @load="(e) => handleImageLoad(e)"
                    />
                    <label
                      class="camera-icon absolute bottom-[-4px] right-[-4px] bg-gray300 w-fit p-2 rounded-full"
                    >
                      <div v-html="CameraIcon"></div>
                    </label>
                  </div>
                </div>
                <div
                  class="delete-btn w-[32px] h-[32px] flex justify-center items-center gap-[6px] px-4 py-2 border-[1px] border-gray300 rounded-lg ml-4 xs:ml-0"
                >
                  <div v-html="grayTrashIcon"></div>
                </div>
              </div>
            </a-form-item>
          </div>
        </div>
        <div
          class="input-row w-full flex flex-col justify-start items-start gap-[12px]"
        >
          <div class="input-row-left noto500 !w-full text-left">
            {{ $t('common.store_name') }}
          </div>
          <div class="input-row-right w-full">
            <a-form-item name="storeName">
              <a-input
                class="!text-text-disabled-gray"
                v-model:value="formState.storeName"
                readonly
              />
            </a-form-item>
          </div>
        </div>
        <div
          class="input-row w-full flex flex-col justify-start items-start gap-[12px]"
        >
          <div class="input-row-left noto500 !w-full text-left">
            {{ $t('common.store_telephone_number') }}
          </div>
          <div class="input-row-right w-full">
            <a-form-item name="storeTelephoneNumber">
              <a-input
                class="!text-text-disabled-gray"
                :type="number"
                v-model:value="formState.storeTelephoneNumber"
                readonly
              />
            </a-form-item>
          </div>
        </div>
        <div
          class="input-row w-full flex flex-col justify-start items-start gap-[12px]"
        >
          <div class="input-row-left noto500 !w-full text-left">
            {{ $t('common.address') }}
          </div>
          <div class="input-row-right w-full">
            <a-form-item name="storeAdress">
              <a-input
                class="!text-text-disabled-gray"
                v-model:value="formState.storeAdress"
                readonly
              />
            </a-form-item>
          </div>
        </div>
        <div
          class="input-row w-full flex flex-col justify-start items-start gap-[12px] cursor-default"
        >
          <div class="input-row-left noto500 !w-full text-left">
            {{ $t('common.area') }}
          </div>
          <div class="input-row-right select-disabled w-full">
            <a-form-item name="storeAdress">
              <a-input
                class="!text-text-disabled-gray"
                v-model:value="formState.storeArea"
                readonly
              />
            </a-form-item>
          </div>
        </div>
        <div
          class="input-row w-full flex flex-col justify-start items-start gap-[12px]"
        >
          <div class="input-row-left noto500 !w-full text-left">
            {{ $t('common.account_creation_date') }}
          </div>
          <div class="input-row-right w-full">
            <a-form-item name="storeCreationDate">
              <a-input
                class="!text-text-disabled-gray"
                v-model:value="formState.storeCreationDate"
                readonly
              />
            </a-form-item>
          </div>
        </div>
        <div
          class="input-row w-full flex flex-col justify-start items-start gap-[12px]"
        >
          <div class="input-row-left noto500 !w-full text-left">
            {{ $t('common.store_name') }}
          </div>
          <div class="input-row-right w-full">
            <a-form-item name="email">
              <a-input
                class="!text-text-disabled-gray"
                v-model:value="formState.email"
                readonly
              />
            </a-form-item>
          </div>
        </div>
        <div
          class="input-row w-full flex flex-col justify-start items-start gap-[12px]"
        >
          <div class="input-row-left noto500 !w-full text-left">
            {{ $t('common.store_image') }}
          </div>
          <div class="input-row-right photo w-full">
            <a-form-item name="storePhoto">
              <label
                class="custom-photo-up border border-gray300 px-4 py-3 rounded-lg text-gray300 flex w-full justify-center items-center whitespace-nowrap"
              >
                <div v-html="uploadDocumentDisabledIcon"></div>
                {{ $t('common.upload') }}
              </label>
            </a-form-item>
            <div
              class="store-photo-list mt-[18px] flex flex-wrap justify-center items-start gap-3"
            >
              <div
                class="store-photo-item relative"
                v-if="(formState?.storePhoto ?? []).length > 0"
                v-for="(item, index) in formState.storePhoto"
                :key="index"
              >
                <img
                  class="store-photo w-[130px] h-[80px] object-cover rounded-lg"
                  :src="getAvatarShopByName(item)"
                  alt="store-photo"
                />
                <div
                  class="delete-photo-icon absolute bottom-1 right-1 bg-gray300 border-[2px] border-white flex items-center justify-center rounded-lg w-[30px] h-[30px]"
                  v-html="trashFilledIconNew"
                  @click="handleDeleteStorePhoto(index)"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="input-submit-row flex !flex-row justify-center items-center gap-[12px]"
        >
          <button
            class="h-[42px] flex flex-1 justify-center items-center text-delete-text border border-delete-text px-2 py-1 md:py-3 md:px-4 rounded-lg whitespace-nowrap"
            @click="handleDeletePermanentlyStoreAccount"
          >
            {{ $t('common.store_deletion') }}
          </button>
          <div class="input-row-right flex flex-1 justify-center items-center">
            <a-form-item class="w-full">
              <button
                class="button-submit !w-full flex justify-center items-center !bg-gray300"
                disabled
              >
                <label v-html="SaveSettingFilled" class="mr-[6px]"></label>
                {{ $t('common.keep') }}
              </button>
            </a-form-item>
          </div>
        </div>
      </a-form>
    </div>
    <div
      v-if="currentTab == 2 && !userTab && layoutMobile"
      class="w-full flex items-center justify-center"
    >
      <UserByStore />
    </div>
    <div
      v-if="currentTab == 2 && userTab == 'active' && layoutMobile"
      class="w-full flex items-center justify-center"
    >
      <UserDetailActive />
    </div>
    <div
      v-if="currentTab == 2 && userTab == 'disable' && layoutMobile"
      class="w-full flex items-center justify-center"
    >
      <UserDetailDisable />
    </div>
    <div
      v-if="currentTab == 2 && userTab == 'pending' && layoutMobile"
      class="w-full flex items-center justify-center"
    >
      <UserDetailDisable />
    </div>
    <div
      v-if="currentTab == 2 && userTab == 'delete' && layoutMobile"
      class="w-full flex items-center justify-center"
    >
      <UserDetailDisable />
    </div>
    <div
      v-if="currentTab == 2 && userTab == 'created' && layoutMobile"
      class="w-full flex items-center justify-center"
    >
      <UserDetailDisable />
    </div>
    <div
      v-if="currentTab == 2 && userTab == 'expired' && layoutMobile"
      class="w-full flex items-center justify-center"
    >
      <UserDetailDisable />
    </div>
    <div
      v-if="currentTab == 3 && layoutMobile"
      class="w-full flex items-center justify-center"
    >
      <AccountPermission />
    </div>
    <div
      v-if="currentTab == 4 && layoutMobile"
      class="w-full flex items-center justify-center"
    >
      <RenewUserAccountPage />
    </div>
  </div>
</template>
<script setup>
import { computed, onMounted, onUnmounted, reactive, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import {
  CameraIcon,
  SaveSettingFilled,
  grayTrashIcon,
  stopIcon,
  trashFilledIconNew,
  uploadDocumentDisabledIcon
} from '../../../assets/constant/constant';
import {
  addModal,
  formatServerDateToDDMMYYYY,
  formatServerDateToJapaneseYYYYMMDD,
  getAvatarShopByName,
  handleImageError
} from '../../../helpers';
import Breadcrumd from '../../master/Breadcrumd.vue';
import ConfirmModal from './components/ConfirmModal.vue';
import UserByStore from '../UserManagermentNew/UserByStore.vue';
import UserDetailActive from '../UserManagermentNew/UserDetailActive.vue';
import UserDetailDisable from '../UserManagermentNew/UserDetailDisable.vue';
import AccountPermission from '../UserManagermentNew/AccountPermission.vue';
import RenewUserAccountPage from '../RenewNew/RenewUserAccountPage.vue';
const { t } = useI18n();
const store = useStore();
const route = useRoute();
const router = useRouter();
const layoutMobile = computed(() => store.state.layoutMobile);
const loadingImage = ref(true);
const currentTab = computed(() => route.query.shop_tab || 1);
const handleImageLoad = () => {
  loadingImage.value = false;
};
const handleDeleteStorePhoto = (index) => {
  formState.storePhoto.splice(index, 1);
};
const formState = reactive({
  storeLogo: '',
  storeName: '',
  storeTelephoneNumber: '',
  storeAdress: '',
  storeArea: '',
  storeCreationDate: '',
  storePhoto: [],
  email: ''
});
const shopInfo = computed(() => {
  return store.state.shopDetails;
});
const areaList = computed(() => store.getters.areaListGetters());
const areaShop = computed(() => {
  return areaList.value?.find((area) => {
    return area.id === shopInfo.value.location;
  })?.value;
});
const routes = computed(() => [
  {
    path: '/dashboard',
    breadcrumbName: t('common.home')
  },
  {
    path: '/store-management',
    breadcrumbName: t('common.stores_management')
  },
  {
    path: '/store/create-store',
    breadcrumbName: formState.storeName
  }
]);
const handleNavigateTabClick = (index) => {
  router.push(
    `/store-management-${shopInfo.value.status}?id=${shopInfo.value._id}&shop_tab=${index}`
  );
};
const navigateTab = computed(() => [
  {
    name: shopInfo.value.name || formState.storeName
  },
  {
    name: t('common.total_users')
  },
  {
    name: t('common.account_permission')
  },
  {
    name: t('common.extended_management')
  }
]);

const nameByIndex = computed(() => {
  return (
    navigateTab.value[currentTab.value - 1]?.name?.replace(/['"]/g, '') ?? ''
  );
});
const handleDeletePermanentlyStoreAccount = () => {
  addModal(ConfirmModal, {
    title: t('common.are_you_sure_you_want_to_delete_this_store_permanently'),
    textBtnClose: t('common.cancel_delete'),
    textBtnConfirm: t('common.confirm'),
    onConfirm: () => {
      store.dispatch('deletePermanentlyShop', {
        shopId: route.query.id,
        navigation: () => {
          router.replace('/store-management');
        }
      });
    },
    action: 'delete'
  });
};
const handleRestoreAccount = () => {
  addModal(ConfirmModal, {
    title: t('common.do_you_want_to_restore_this_store'),
    textBtnClose: t('common.cancel_delete'),
    textBtnConfirm: t('common.confirm'),
    onConfirm: () => {
      store.dispatch('restoreShop', {
        shopId: route.query.id,
        navigation: () => {
          router.replace(`/store-management-active?id=${shopInfo.value?._id}`);
        }
      });
    },
    action: ''
  });
};

watch(shopInfo, () => {
  if (
    shopInfo.value.status !== 'disable' &&
    shopInfo.value.status === 'active'
  ) {
    router.replace(
      `/store-management-${shopInfo.value.status}?id=${shopInfo.value._id}`
    );
  }
  formState.storeLogo = shopInfo.value.avatar;
  formState.storeName = shopInfo.value.name;
  formState.storeTelephoneNumber = shopInfo.value.phone;
  formState.storeAdress = shopInfo.value.address;
  formState.storeArea = shopInfo.value.location;
  formState.email = shopInfo.value.email;
  formState.storeCreationDate = formatServerDateToJapaneseYYYYMMDD(
    shopInfo.value?.createdAt
  );

  formState.storePhoto = (shopInfo.value.images ?? []).filter((img) => {
    return img !== null && typeof img === 'string';
  });
});
watch(areaShop, () => {
  formState.storeArea = areaShop.value;
});
onMounted(() => {
  if (areaList && areaList.value.length <= 0) {
    store.dispatch('getAreaList');
  }
  store.dispatch('getShopDetails', {
    shopId: route.query.id,
    navigationError: () => {
      router.replace('/store-management');
    }
  });
});
onUnmounted(() => {
  store.dispatch('clearShopDetail');
});
</script>
<style lang="scss">
$white-primary: white;
$gray-300: #d0d5dd;
$gray-500: #667085;
$gray-700: #344054;
$orange-500: #f38744;
.store-info-disable {
  .ant-select-selector {
    cursor: default;
  }
  .ant-select-selection-placeholder {
    cursor: default;
  }
}
img {
  border: 1px solid $gray-300;
}
input[type='file'] {
  display: none !important;
}
.form-header {
  @media (max-width: 670px) {
    flex-direction: column;
    gap: 10px;
    .form-header-left {
      align-self: flex-start;
    }
  }
}
.input-submit-row,
.input-row {
  padding: 20px 12px 20px 0;
  display: flex;
  justify-content: start;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid $gray-300;
  @media (max-width: 800px) {
    flex-direction: column;
  }
  .input-row-left {
    min-width: 200px;
    width: 15%;
    color: $gray-500;
    font-size: 14px;
    @media (max-width: 800px) {
      width: 100%;
    }
  }
  .input-row-right {
    flex: 1;
    @media (max-width: 800px) {
      width: 100%;
    }
    .button-submit {
      background-color: $orange-500;
      color: $white-primary;
      display: flex;
      align-items: center;
      gap: 5px;
      padding: 12px 16px;
      border-radius: 8px;
    }
  }
}
.input-submit-row {
  border-bottom: 0;
  .input-row-right {
    width: fit-content;
  }
  @media (max-width: 800px) {
    align-items: center !important;
    justify-content: center !important;
  }
}
.ant-form-item {
  margin-bottom: 0 !important;
}
.ant-input:focus,
.ant-input:hover {
  border-color: $gray-300;
  outline: none;
  box-shadow: none;
}
@media only screen and (max-width: 1100px) {
  .create-store-form {
    width: 90% !important;
  }
}
.store-info-container {
  input {
    font-family: Noto400;
    font-size: 14px;
    line-height: 22px;
    color: #1d2939;
  }
  .ant-picker {
    width: 100% !important;
    padding-left: 0px !important;
  }
  // .ant-picker-input {
  //   input {
  //     text-align: left !important;
  //   }
  // }
}
</style>
